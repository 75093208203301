let users = {

    superadmin: {
        boxes: true,

        manageSiteB: true,

        clients: {
            view: true,
            edit: true,
        },
        appointments: {
            view: true,
            create: true,
            edit: true,
            delete: true,
        },
        orders: {
            view: true,
            create: true,
            deleteRenata: true,
            notesPatrizio: false,
            edit: true,
            manageMaterials: true,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: true,
        },
        workersVehicles: {
            view: true,
            edit: true,
        },
        resources: {
            menu: true,
            view: true,
            edit: true,
        },
        works: {
            deleteRenata: true,
            view: true,
            edit: true,
        },
        materials: {
            view: true,
            edit: true,
        },
        planning: {
            view: true,
            edit: true,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: false,
        adminLog: true,
    },

    federica: {
        boxes: true,

        clients: {
            view: true,
            edit: true,
        },
        appointments: {
            view: true,
            create: true,
            edit: true,
            delete: true,
        },
        orders: {
            view: true,
            create: true,
            deleteRenata: true,
            notesPatrizio: false,
            edit: true,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: true,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: true,
        },
        works: {
            deleteRenata: true,
            view: true,
            edit: true,
        },
        materials: {
            view: true,
            edit: true,
        },
        planning: {
            view: true,
            edit: true,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: true,
        modifyDailyWorks: true,
        logVehicle: true,
        logWorkers: true,
        agentsStats: true,
		editFlagRenata: true,
		editResourcesB: true,
        editDeposit: true,
    },

    imma: {
        editDeposit: true,

        boxes: true,

        clients: {
            view: true,
            edit: true,
        },
        appointments: {
            view: true,
            create: true,
            edit: true,
            delete: true,
        },
        orders: {
            view: true,
            create: true,
            deleteRenata: true,
            notesPatrizio: false,
            edit: true,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: true,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: true,
        },
        works: {
            deleteRenata: true,
            view: true,
            edit: true,
        },
        materials: {
            view: true,
            edit: true,
        },
        planning: {
            view: true,
            edit: true,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: true,
    },

    fabrizia: {
        boxes: true,

        clients: {
            view: true,
            edit: true,
        },
        appointments: {
            view: true,
            create: true,
            edit: true,
            delete: true,
        },
        orders: {
            view: true,
            create: true,
            deleteRenata: true,
            notesPatrizio: false,
            edit: true,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: true,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: true,
        },
        works: {
            deleteRenata: true,
            view: true,
            edit: true,
        },
        materials: {
            view: true,
            edit: true,
        },
        planning: {
            view: true,
            edit: true,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: true,
    },

    claudio: {
        boxes: true,

        addWorkInDailyWork: true,

        clients: {
            view: true,
            edit: false,
        },
        appointments: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        orders: {
            view: true,
            create: false,
            deleteRenata: false,
            notesPatrizio: false,
            edit: false,
        },
        documents: {
            view: true,
            showInCharge: true,
            edit: false,
        },
        workersVehicles: {
            view: true,
            edit: true,
        },
        resources: {
            menu: false,
            view: true,
            edit: false,
        },
        works: {
            deleteRenata: false,
            view: true,
            edit: false,
        },
        materials: {
            view: true,
            edit: true,
        },
        planning: {
            view: true,
            edit: false,
            onlyClaudio: true,
        },
        viewDailyWorksMenu: true,
        modifyDailyWorks: true,
    },

    gennaro: {
        boxes: true,

        clients: {
            view: true,
            edit: false,
        },
        appointments: {
            view: true,
            create: false,
            edit: true,
            delete: false,
        },
        orders: {
            view: true,
            create: false,
            deleteRenata: false,
            notesPatrizio: false,
            edit: false,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: false,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: false,
        },
        works: {
            deleteRenata: false,
            view: true,
            edit: false,
        },
        materials: {
            view: true,
            edit: false,
        },
        planning: {
            view: true,
            edit: false,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: true,
    },

    angela: {
        boxes: true,

        clients: {
            view: true,
            edit: false,
        },
        appointments: {
            view: true,
            create: true,
            edit: true,
            delete: true,
        },
        orders: {
            view: true,
            create: false,
            deleteRenata: false,
            notesPatrizio: false,
            edit: false,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: false,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: false,
        },
        works: {
            deleteRenata: false,
            view: true,
            edit: false,
        },
        materials: {
            view: true,
            edit: false,
        },
        planning: {
            view: true,
            edit: false,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: true,
    },

    renata: {
        editDeposit: true,
        
        boxes: true,
        
        clients: {
            view: true,
            edit: false,
        },
        appointments: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        orders: {
            view: true,
            create: false,
            deleteRenata: true,
            notesPatrizio: false,
            edit: false,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: false,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: false,
        },
        works: {
            deleteRenata: true,
            view: true,
            edit: false,
        },
        materials: {
            view: true,
            edit: false,
        },
        planning: {
            view: true,
            edit: false,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: false,
    },

    patrizio: {
        boxes: true,
        
        clients: {
            view: true,
            edit: false,
        },
        appointments: {
            view: true,
            create: false,
            edit: false,
            delete: false,
        },
        orders: {
            view: true,
            create: false,
            deleteRenata: false,
            notesPatrizio: true,
            edit: false,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: false,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: false,
        },
        works: {
            deleteRenata: false,
            view: true,
            edit: false,
        },
        materials: {
            view: true,
            edit: false,
        },
        planning: {
            view: true,
            edit: false,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: true,
        agentsStats: true,
    },

    vincenzo: {
        boxes: false,

        clients: {
            view: true,
            edit: true,
        },
        appointments: {
            view: true,
            create: true,
            edit: true,
            delete: true,
        },
        orders: {
            view: true,
            create: false,
            deleteRenata: false,
            notesPatrizio: false,
            edit: false,
        },
        documents: {
            view: false,
            showInCharge: false,
            edit: false,
        },
        workersVehicles: {
            view: false,
            edit: false,
        },
        resources: {
            menu: false,
            view: false,
            edit: false,
        },
        works: {
            deleteRenata: false,
            view: false,
            edit: false,
        },
        materials: {
            view: true,
            edit: true,
        },
        planning: {
            view: true,
            edit: true,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: false,
        modifyDailyWorks: false,
    },

    matteo: {
        boxes: true,

        clients: {
            view: true,
            edit: true,
        },
        appointments: {
            view: true,
            create: true,
            edit: true,
            delete: true,
        },
        orders: {
            view: true,
            create: true,
            deleteRenata: true,
            notesPatrizio: false,
            edit: true,
        },
        documents: {
            view: true,
            showInCharge: false,
            edit: true,
        },
        workersVehicles: {
            view: true,
            edit: false,
        },
        resources: {
            menu: false,
            view: true,
            edit: true,
        },
        works: {
            deleteRenata: true,
            view: true,
            edit: true,
        },
        materials: {
            view: true,
            edit: true,
        },
        planning: {
            view: true,
            edit: true,
            onlyClaudio: false,
        },
        viewDailyWorksMenu: true,
        modifyDailyWorks: true,
        logVehicle: true,
        logWorkers: true,
        agentsStats: true,
		editFlagRenata: true,
		editResourcesB: true,
        editDeposit: true,
    },
};

export default users