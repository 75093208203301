<template>
  <div>  

    <br />

    <v-container>
      <v-row>
        <v-col>
          <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">Modifica lavorazione</h1></v-subheader>
        </v-col>
      </v-row>
    </v-container>
    <br />

        <v-card  class="mx-auto" max-width="800">
          <v-card-actions>

            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showWorkersVehiclesDialog(currentWorkId)" class="mr-3" color="orange" icon fab v-bind="attrs" v-on="on"><v-icon large>mdi-account-hard-hat</v-icon></v-btn>
              </template>
              <span>Gestire operai e automezzi</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <!--

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showEditWorkers(currentWorkId)" class="mr-3" color="orange" icon fab v-bind="attrs" v-on="on"><v-icon large>mdi-account-hard-hat</v-icon></v-btn>
              </template>
              <span>Gestire operai</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showEditVehicles(currentWorkId)" class="mr-3" color="blue-grey" icon fab v-bind="attrs" v-on="on"><v-icon large>mdi-truck</v-icon></v-btn>
              </template>
              <span>Gestire automezzi</span>
            </v-tooltip>

            <v-spacer></v-spacer>

            -->

            <v-tooltip bottom v-if="$store.getters.rules.resources.view">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="showEditResources(currentWorkId)" class="mr-3" color="info" icon fab v-bind="attrs" v-on="on"><v-icon large>mdi-hammer-screwdriver</v-icon></v-btn>
              </template>
              <span>Gestire risorse</span>
            </v-tooltip>

            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>

        <br />
        
        <v-card class="mx-auto" max-width="800">
          <v-card-text>
            <v-form ref="formWorks" lazy-validation>

            <template>
                <v-menu
                    ref="calendarFormWorks"
                    v-model="calendarFormWorks"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="worksForm.date"
                        label="Data*:"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required && rules.date" 
                        required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                  ref="picker"
                  v-model="worksFormOriginalFormat"
                  min="1950-01-01"
                  locale="it"
                  :first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
            </template>

                <v-text-field
                    v-model="worksForm.hour"
                    label="Ora*:"
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules.required && rules.hour"
                    required
                ></v-text-field>

            <v-switch v-model="worksForm.show_worksheet" color="success" label="Visibile in schermata lavorazioni (claudio)"></v-switch>
            <v-switch
			v-if="$env.siteA && $store.getters.rules.editFlagRenata"
              label="Renata"
              color="success"
              :false-value="0"
              :true-value="'1'"
              v-model="worksForm.flag_renata"
            ></v-switch>
            <v-select v-model="worksForm.type" label="Tipologia lavoro:" :items="itemsWorkType"></v-select>
            <v-select v-model="worksForm.id_pickupPlace" label="Indirizzo del lavoro*:" :items="itemsWorksPlaces" :rules="[$rules.required]"></v-select>
            <v-textarea v-model="worksForm.notes" label="Note:"></v-textarea>
            <v-text-field v-model="worksForm.police_permission" :rules="rules.integer" type="number" label="Costo Permesso:" prefix="€"></v-text-field>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="$router.go(-1)">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="processWorkForm" v-if="$store.getters.rules.works.edit">Modifica</v-btn>
        </v-card-actions>
      </v-card>





    <!-- Works -->

     <!-- Resources -->
    <v-dialog
      v-model="overlayResources"
      scrollable
      max-width="800px"
    >
      <v-card light min-width="800">
        <v-card-title>Gestire risorse</v-card-title>
        <v-card-text>

          <v-simple-table>
            <thead>
              <tr>
                <th>
                  Risorsa
                </th>
                <th>
                  Quantità
                </th>
                <th>
                  Disp. / Totali
                </th>  
              </tr>
            </thead>
            <tbody>
              <tr v-for="(resource, index) in resourcesLabels" v-bind:key="index">
                <td>
                  {{resource.name}}
                </td>
                <td>
                  <v-text-field type="number" hide-details dense outlined v-model="resourcesValues[resource.id]" v-if="$store.getters.rules.works.edit"></v-text-field>
                  <span v-if="!$store.getters.rules.works.edit">
                    {{ resourcesValues[resource.id] }}
                  </span>
                </td>
                <td>
                  <span :class="(resourcesAvailable[resource.id]<0) ? 'red--text' : ''">
                    {{resourcesAvailable[resource.id]}}
                    / {{resource.ammount}}
                    </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayResources = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveResources" v-if="$store.getters.rules.works.edit">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  

    <!-- Workers and vehicles -->

    <v-dialog
      v-model="overlayEditWorkers"
      scrollable
      max-width="800px"
    >
    <v-card light min-width="800">
        <v-card-title>Gestire operai ({{selectedWorkers.length}})</v-card-title>
        <v-card-text>
          <br />
          <h4>Operai: </h4>
          <br />
          <div v-if="selectedWorkers.length>0">
            <h3>{{ selectedWorkersString }}</h3>
          </div>
          <div v-if="selectedWorkers.length==0">
            Nessun operaio selezionato.
          </div>
          <br />
          <div v-if="$store.getters.rules.workersVehicles.edit">
            <v-divider></v-divider>
            <br />
            <v-form ref="formWorkers">

              <v-row>
                <v-col cols="6" v-for="(worker, index) in workers" v-bind:key="index" style="padding: 5px">
                  <v-checkbox  :value="worker.value" v-model="selectedWorkers" dense style="margin: 0px" :label="worker.text"></v-checkbox>
                </v-col>
              </v-row>            

            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayEditWorkers=false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn small text @click="selectedWorkers=[]" v-if="$store.getters.rules.workersVehicles.edit"><v-icon color="red">mdi-close</v-icon> Cancella tutto</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmSelectedWorkers" v-if="$store.getters.rules.workersVehicles.edit">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="overlayEditVehicles"
      scrollable
      max-width="800px"
    >
    <v-card light min-width="800">
        <v-card-title>Gestire automezzi ({{selectedVehicles.length}})</v-card-title>
        <v-card-text>

          <br />
          <h4>Automezzi: </h4>
          <br />
          <div v-if="selectedVehicles.length>0">
            <h3>{{ selectedVehiclesString }}</h3>
          </div>
          <div v-if="selectedVehicles.length==0">
            Nessun automezzo selezionato.
          </div>
          <br />
          <div v-if="$store.getters.rules.workersVehicles.edit">
            <v-divider></v-divider>
            <br />

            <v-form ref="formVehicles">

              <v-row>
                <v-col cols="6" v-for="(vehicle, index) in vehicles" v-bind:key="index" style="padding: 5px">
                  <v-checkbox :value="vehicle.value" v-model="selectedVehicles" dense style="margin: 0px" :label="vehicle.text"></v-checkbox>
                </v-col>
              </v-row>  

            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayEditVehicles=false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn small text @click="selectedVehicles=[]" v-if="$store.getters.rules.workersVehicles.edit"><v-icon color="red">mdi-close</v-icon> Cancella tutto</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmSelectedVehicles" v-if="$store.getters.rules.workersVehicles.edit">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="overlayWorkersVehicles"
      scrollable
      max-width="1200px">

      <v-card light min-width="1200">
        <v-card-title>Gestire operai e automezzi</v-card-title>
        <v-card-text>
        <worker-vehicle-single-work v-bind:workId="currentWorkId" v-bind:workDate="worksForm.date"></worker-vehicle-single-work>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="overlayWorkersVehicles=false">Chiudi</v-btn>
        </v-card-actions>
      </v-card>   
         
    </v-dialog>





  </div>
</template>

<script>

import {workers, vehicles} from '../../global';

import WorkerVehicleSingleWork from '../../components/WorkerVehicleSingleWork.vue'

export default {

  components: {
    WorkerVehicleSingleWork
  },
 
  data() {
    return {

      overlayWorkersVehicles: false,

      workers,
      vehicles,
      selectedWorkers: [],
      selectedVehicles: [],

     rules: {
      required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
      integer: [ value => (! /^[0-9]+$/.test(value)) ? 'Inserire un numero intero valido.' : true ],
      hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
      date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
      numberOrNull: [ value => (!value) ? 'Inserire un numero intero valido.' : true ],
     },

     modifyOrderOverlay: false,

      modifyForm: {
        agreed_price: null,      
		agreed_price_2: null,     
        payment_type: null,
        notes: null,
      },

      paymentTypeText: [
        'Contanti',
        'Assegno',
        'Bonifico',
      ],

      paymentType: [
        {text: 'Contanti', value:'0'},
        {text: 'Assegno', value:'1'},
        {text: 'Bonifico', value:'2'},
      ],

      /* Works */     
      calendarFormWorks: false,
      worksFormOriginalFormat: '',
      hourFormWorks: false,
      currentWorkId: false,
      deleteWorkId: false,
      overlayConfirmDeleteWork: false,

      calendarMaterial: false,
      dateMaterialOriginalFormat: null,

      worksForm: {
        date: null,
        hour: null,
        show_worksheet: null,
        type: null,
        id_pickupPlace: null,
        notes: null,
        police_permission: 0,
        flag_renata: 0,
      },

      overlayWorks: false,
      headersWorks: [
        {text:'Data', value:'date'},
        {text:'Ora', value:'hour'},
        {text:'Tipologia', value:'type'},
        {text:'Sk.Lavorazioni', value:'show_worksheet'},
        {text:'Azioni', value:'actions'},
      ],
      itemsWorkType: [],

      itemsWorksPlaces: [],

      // Materials
      headersMaterials: [
        {text:'Materiale', value:'material'},
        {text:'Quantità', value:'quantity'},
        {text:'Prezzo', value:'price'},
        {text:'Consegnato', value:'delivered'},
        {text:'Azioni', value:'actions'},
      ],

      confirmDeleteMaterial: false,

      materialsForm: {
        data: null,
        material: null,
        quantity: null,
        price: null,
        delivered: null,
      },

      currentMaterialPopupButton: '-1',
      overlayMaterials: false,
      itemsMaterials: [
        'Bauletti Porta Abiti',
        'Giornali (pedana 1500Kg / 85 ballette)',
        'Matrimoniali (650gr) coperture',
        'Pluriball',
        'Scatola Barile',
        'Scatole Fragili',
        'Scatole Libri',
        'Scotch 52 Mycron - 66 MT',
        'Singole (380gr) coperture',
      ],
      fixedCostPerUnit: [
        6,
        0,
        0,
        100,
        1.5,
        1.5,
        1.5,
        0,
        0,
      ],
      currentMaterialId: false,
      materialCostPerUnit: null,

      // Order
      order: {},

      // Resources
      overlayResources: false,
      resourcesNames: [
        'prueba 1',
        'prueba 2'
      ],
      resourcesLabels: [],
      resourcesValues: [],
      resourcesAvailable: [],
      resources_raw: [],

      overlayEditWorkers: false,
      overlayEditVehicles: false,
      on: false,
      attrs: false,

    }
  },

  methods: {


    /*
      showMaterialsPopupButtons: function(id) {      
        this.currentMaterialPopupButton = id;
      },

      hideMaterialsPopupButtons: function() {
        this.currentMaterialPopupButton = '-1';
      },
    */

    // Edit work
    getWork(id) {

      this.$api.orders.getWork(id).then(data => {
        this.worksForm = data.values.work;
        this.itemsWorksPlaces = data.values.places;
      });

      this.getResourcesLabels();

        //this.worksForm = this.order.works[index];
        /*
          date: null,
          hour: null,
          show_worksheet: null,
          type: null,
          id_pickupPlace: null,
          notes: null,
          police_permission: null,
        */
    },

    // Materials
    editMaterial(id) {
      this.currentMaterialId = id;
      let currentMaterial = this.order.materials.find(el => el.id==id); 
      this.materialsForm = currentMaterial;
      this.overlayMaterials = true;
    },

    deleteMaterial(id) {
      this.currentMaterialId = id;
      this.confirmDeleteMaterial = true;
    },

    deleteMaterialConfirmed() {
      this.$api.orders.deleteMaterial(this.currentMaterialId).then(data => {
          this.$emit('hideProgress');

          if (!data.ok) {
              this.$emit('showSnack', data.values.msg, 'error');
              return;
          }

          // Ok
          this.$emit('showSnack', 'Materiale eliminato', 'success');
          this.confirmDeleteMaterial = false;
          this.getOrder();
      });
    },

    newMaterial() {
      this.currentMaterialId = false;
      this.materialsForm = {
        data: null,
        material: null,
        quantity: null,
        price: null,
        delivered: null,
      };
      this.overlayMaterials = true;
    },

    cancelMaterialForm() {
      this.overlayMaterials = false;
      this.getOrder();
    },

    confirmMaterials() {
      if (!this.$refs.formMaterials.validate())
        return;
        
      this.$emit('showProgress');

      if (!this.currentMaterialId) {
        // New        
        this.$api.orders.newMaterial(this.order.id, this.materialsForm).then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            // Ok
            this.getOrder();
            this.$emit('showSnack', 'Materiale inserito', 'success');
            this.overlayMaterials = false;
        });
      } else {
        // Edit
        this.$api.orders.editMaterial(this.currentMaterialId, this.materialsForm).then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            // Ok
            this.getOrder();
            this.$emit('showSnack', 'Materiale modificato', 'success');
            this.overlayMaterials = false;
        });
      }      
    },

    materialsFormCalendarSave(date) {
      this.$refs.calendarMaterial.save(date);
    },
 
    confirmModifyOrder() {
      if (!this.$refs.modifyForm.validate())
        return;

      this.modifyForm = {
        agreed_price: this.order.agreed_price,
		agreed_price_2: this.order.agreed_price_2,
        payment_type: this.order.payment_type,
        renata: this.order.renata,
        notes: this.order.notes,
      };

      this.$emit('showProgress');
      //alert(this.order.id);
      this.$api.orders.modify(this.order.id, this.modifyForm).then(data => {
          this.$emit('hideProgress');
          this.modifyOrderOverlay = false;

          if (!data.ok) {
              this.$emit('showSnack', data.values.msg, 'error');
              return;
          }

          // Ok
          this.$emit('showSnack', 'Ordine modificato correttamente', 'success');
      });
    },

    getOrder() {
      this.$api.orders.getFromId(this.$route.params.id).then(data => {
        this.order = data.values.order;

        // Materials boolean string to int
        this.order.materials.forEach((el, index) => {
          this.order.materials[index].delivered = (el.delivered=="1") ? 1 : 0;
        });

        // Works boolean string to int
        this.order.works.forEach((el, index) => {
          this.order.works[index].show_worksheet = (el.show_worksheet=="1") ? 1 : 0;          
        });

        this.itemsWorksPlaces = data.values.order.places;
        this.resourcesLabels = data.values.order.resources; // id, text
      });      
    },

    getResourcesLabels() {
      // Get resourcesLabels
      this.$api.works.getResourcesLabels().then(data => {
        this.resourcesLabels = data.values.resources; // id, text
      }); 
    },

    // Works
    newWork() {
      this.currentWorkId=false;
      this.worksForm = {
        date: null,
        hour: null,
        show_worksheet: false,
        type: null,
        id_pickupPlace: null,
        notes: null,
        police_permission: 0,
      };
      this.overlayWorks=true;
    },
    
    processWorkForm() {
      if (!this.$refs.formWorks.validate())
        return;

      this.$emit('showProgress');

      // Edit
      this.$api.orders.editWork(this.currentWorkId, this.worksForm).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.$emit('showSnack', 'Lavorazione modificata', 'success');
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
 
    },

    editWork(id) {
      // Get work info from order
      this.currentWorkId = id;

      let index = this.order.works.findIndex(el => el.id == id);
      this.worksForm = this.order.works[index];
      /*
        date: null,
        hour: null,
        show_worksheet: null,
        type: null,
        id_pickupPlace: null,
        notes: null,
        police_permission: null,
      */
      this.overlayWorks = true;
    },

    debug(test) {
      alert(test);
    },

    showDeleteWorkDialog(id) {
      this.deleteWorkId = id;
      this.overlayConfirmDeleteWork = true;
    },

    confirmDeleteWork() {
      this.$emit('showProgress');

      this.$api.orders.deleteWork(this.deleteWorkId).then(data => {
        this.$emit('hideProgress');
        this.overlayConfirmDeleteWork = false;
        if (data.ok) {
          this.getOrder();
          this.$emit('showSnack', 'Lavorazione eliminata', 'success');
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },

    // Resources
    showEditResources(id) {
      this.currentWorkId_resource = id;
      // Load resources values for current work
      this.$emit('showProgress');

      this.$api.orders.getWorkResources(id).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {

        //            
        //            this.resourcesAvailable[el.id_resource] = el.available;

          // Set all values to 0
          // Set availability by default to total ammount
          this.resourcesValues = [];
          this.resourcesAvailable = [];
          this.resourcesLabels.forEach(el => {
            this.resourcesValues[el.id] = 0;
            this.resourcesAvailable[el.id] = el.ammount;
          });        

          data.values.resources.forEach(el => {
            this.resourcesValues[el.id_resource] = el.value;
          });
          
          // Get used resources and then do available - used
          if (data.values.availability) {
            data.values.availability.forEach(el => {
              // This will show me all used resources during the day of current order
              /*
                Example
                  id_resource	  used 	
                  4 			      2
                  5 			      2
              */
              let resourceTotal = this.resourcesAvailable[el.id_resource];             
              this.resourcesAvailable[el.id_resource] = parseInt(resourceTotal) - parseInt(el.used);
            });
          }

          // Show dialog
          this.overlayResources = true;
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },

    saveResources() {

      this.$emit('showProgress');

      // Create array of resource ID and new value
      let values = [];
      this.resourcesValues.forEach((el, index) => {
        // index => el
        values.push({id_work: this.currentWorkId_resource, id_resource: index, value: el});
      });

      this.$api.orders.saveWorkResources(this.currentWorkId_resource, values).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          // Hide dialog
          this.overlayResources = false;
          this.$emit('showSnack', 'Risorse aggiornate', 'success');
        } else {
          this.$emit('showSnack', data.values.msg, 'error');
        }
      }); 

    },

    updateCostAndPrice() {
      if (this.materialsForm.material && !isNaN(this.materialsForm.quantity)) {
        this.materialCostPerUnit = this.fixedCostPerUnit[this.itemsMaterials.findIndex( el => el == this.materialsForm.material )];
        this.materialsForm.price = parseInt(this.materialCostPerUnit) * this.materialsForm.quantity;
      } else {
        this.materialCostPerUnit = null;
        this.materialsForm.price = null;
      }
    },

    showEditWorkers(id) {
      this.currentWorkId = id; 
      
      // Load workers for current work
      this.$emit('showProgress');

      this.$api.works.getWorkers(this.currentWorkId).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.selectedWorkers = data.values.workers;
        } else {
          this.$emit('showSnack', 'Errore: lista operai non non trovata', 'error');
        }
      });

      this.overlayEditWorkers = true;
    },

    confirmSelectedWorkers() {
      this.$emit('showProgress');

      this.$api.works.setWorkers(this.currentWorkId, this.selectedWorkers).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.overlayEditWorkers = false;
          this.$emit('showSnack', 'Lista operai aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista operai non aggiornata', 'error');
        }
      });
    },

    showEditVehicles(id) {
      this.currentWorkId = id; 
      
      // Load workers for current work
      this.$emit('showProgress');

      this.$api.works.getVehicles(this.currentWorkId).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.selectedVehicles = data.values.vehicles;
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non trovata', 'error');
        }
      });

      this.overlayEditVehicles = true;
    },

    confirmSelectedVehicles() {
      this.$emit('showProgress');

      this.$api.works.setVehicles(this.currentWorkId, this.selectedVehicles).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.overlayEditVehicles = false;
          this.$emit('showSnack', 'Lista automezzi aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non aggiornata', 'error');
        }
      });
    },

    showWorkersVehiclesDialog() {
      this.overlayWorkersVehicles = true;
    },

    // Load work types from DB
    getWorkTypes() {
      this.$axios.post(this.$env.api+'Works/', {funct: 'getWorkTypes'}).then(({data}) => {
        if (data.ok) {
          this.itemsWorkType = data.values.workTypes.map(el => { return {value: el.value, text: el.text} });
        }                    
      });
    },

  },

  computed: {

    selectedWorkersString: function () {
      let list = [];

      this.workers.forEach(el => {
        if (this.selectedWorkers.includes(el.value))
          list.push(el.text);
      });

      return list.join(', ');
    },

    selectedVehiclesString: function () {
      let list = [];

      this.vehicles.forEach(el => {
        if (this.selectedVehicles.includes(el.value))
          list.push(el.text);
      });

      return list.join(', ');
    },

  },

  watch: {

    dateMaterialOriginalFormat(val) {
        this.materialsForm.date = val.split('-').reverse().join('-');
    },

     worksFormOriginalFormat(val) {
        this.worksForm.date = val.split('-').reverse().join('-');
    },

  },

  // Events
  mounted() {
    this.getWorkTypes();
    this.currentWorkId = this.$route.params.id; 
    this.getWork(this.currentWorkId);
  },

}

</script>